import { createStyles, Text, Title } from "@mantine/core";

import Image from "next/image";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: `calc(${theme.spacing.xl} * 2)`,

    backgroundColor: "#828C34",

    [theme.fn.smallerThan("sm")]: {
      flexDirection: "column-reverse",
      padding: theme.spacing.xl,
    },
  },

  image: {
    maxWidth: "40%",

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  body: {
    paddingRight: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan("sm")]: {
      paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
  },

  controls: {
    display: "flex",
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: "100%",
    flex: "1",
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export function SobreMim() {
  const { classes } = useStyles();
  return (
    <div key={"sobre"} id="sobre" className={`${classes.wrapper}`}>
      <Image
        src={"/logo_icon.png"}
        alt="Icone da logo"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
        width={800}
        height={800}
        className={classes.image}
      />
      <div className={classes.body}>
        <Title className={"text-white text-5xl"} weight={"bold"}>
          Sobre a Dra. Gabriella Martins
        </Title>
        <Text className="text-xl " color="white" align="left">
          <p className="m-10 ml-0">
            A Dra. Gabriella Martins é médica generalista formada em 2021 pela
            Universidade Federal de Alfenas, em Minas Gerais. Iniciou seu
            interesse sobre o uso terapêutico da Cannabis logo após a graduação
            e hoje é médica prescritora de Cannabis Medicinal.
          </p>

          <p className="m-10 ml-0">
            Atualmente, está cursando pós-graduação em Medicina Canabinoide, com
            o intuito de se especializar ainda mais nesta área que é ainda tão
            carente de profissionais, mesmo diante do enorme potencial
            terapêutico da Cannabis.
          </p>
          <p className="m-10 ml-0">
            Hoje, com 26 anos, além de atender como médica prescritora de
            Cannabis, atua também na Unidade de Pronto Atendimento na cidade em
            que reside.
          </p>
        </Text>
      </div>
    </div>
  );
}
