import { HEADER_VALUES } from "@/utils/consts";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Image from "next/image";
import Link from "next/link";

const HEADER_HEIGHT = rem(70);

const useStyles = createStyles((theme) => ({
  root: {
    position: "sticky",
    zIndex: 1,
  },

  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    border: "none",
    background: "#363f1d",
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },

    [theme.fn.smallerThan("md")]: {
      border: "1px solid #989898a7",
    },
  },

  header: {
    display: "flex",
    maxWidth: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    height: "100%",
    [theme.fn.smallerThan("lg")]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: "#FFFFFF",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: "#363f1d",
      color: "white",
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      color: "white",
      padding: theme.spacing.md,
    },
  },
}));

interface HeaderActionProps {
  links: { link: string; label: string }[];
}

export default function HeaderAction({ links }: HeaderActionProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const { classes } = useStyles();

  const items = links.map((link) => (
    <Link
      target={link.label === HEADER_VALUES[2].label ? "_blank" : "_self"}
      key={link.label}
      href={link.link}
      className={classes.link}
    >
      {link.label}
    </Link>
  ));

  return (
    <Header
      height={HEADER_HEIGHT}
      className={`${classes.root} bg-light-verdeEscuroCTX border-none`}
    >
      <Container className={classes.header}>
        <Container
          size={"xl"}
          className="flex justify-between items-end pb-2 w-full"
        >
          <Image
            src={"/logo_gabi_martins.svg"}
            alt="Logo Gabriella Martins"
            width={145}
            height={145}
            className="-ml-3"
          />
          <Group spacing={5} className={classes.links}>
            {items}
          </Group>
        </Container>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          color="white"
          size="sm"
        />

        <Transition transition="scale-y" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} shadow="xl" style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}
