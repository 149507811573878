import {
  ThemeIcon,
  Text,
  Title,
  Container,
  SimpleGrid,
  createStyles,
  rem,
} from "@mantine/core";
import Image from "next/image";
import Link from "next/link";

export const MOCKDATA = [
  {
    icon: "/icones/livro_canabis.png",
    title: "Potencial Medicinal da Cannabis",
    description: "Descubra os benefícios terapêuticos da cannabis.",
  },
  {
    icon: "/icones/gota.png",
    title: "CBD: Benefícios Terapêuticos",
    description:
      "Conheça os benefícios do CBD no tratamento de diversas condições.",
  },
  {
    icon: "/icones/seringa.png",
    title: "Cannabis Medicinal para Dor Crônica",
    description: "Alívio da dor crônica com a cannabis medicinal.",
  },
  {
    icon: "/icones/laco.png",
    title: "Cannabis e Epilepsia: Alternativa Promissora",
    description: "Cannabis como opção de tratamento para epilepsia.",
  },
  {
    icon: "/icones/sono.png",
    title: "Cannabis e Saúde Mental: Ansiedade e Depressão",
    description: "O papel da cannabis no tratamento de ansiedade e depressão.",
  },
  {
    icon: "/icones/pocao.png",
    title: "O auxílio preciso em Cuidados Paliativos",
    description: "Alívio e conforto para pacientes terminais com cannabis.",
  },
];

interface BenefitProps {
  icon: string;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Benefit({ icon, title, description }: BenefitProps) {
  return (
    <div className="p-10">
      <div className="flex justify-center">
        <ThemeIcon variant="light" color="lime" size={150} radius={150}>
          <Image src={icon} width={125} height={125} alt="icone" />
        </ThemeIcon>
      </div>
      <Text color="#007D6D" weight={"bold"} align="center" mt="sm" mb={7}>
        {title}
      </Text>
      <Text align="center" size="sm" color="#00332D" sx={{ lineHeight: 1.6 }}>
        {description}
      </Text>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left",
    },
  },
}));

interface FeaturesGridProps {
  data?: BenefitProps[];
}

export function BenefitGrid({ data = MOCKDATA }: FeaturesGridProps) {
  const { classes } = useStyles();
  const features = data.map((feature, index) => (
    <Benefit {...feature} key={index} />
  ));

  return (
    <Container className={classes.wrapper}>
      <SimpleGrid
        mt={60}
        cols={3}
        spacing={50}
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: "xl" },
          { maxWidth: 755, cols: 1, spacing: "xl" },
        ]}
      >
        {features}
      </SimpleGrid>
      <div className="flex justify-center w-full">
        <Link
          href={
            "https://agendaronline.amplimed.com.br/gabriella-de-oliveira-martins"
          }
          target="_blank"
          className="w-3/4"
        >
          <button className="bg-light-verdeClaroCTX p-6 w-full rounded-full text-white font-bold">
            Agende agora sua consulta com a Dra. Gabriella Martins
          </button>
        </Link>
      </div>
    </Container>
  );
}
