import {
  createStyles,
  rem,
  Group,
  Button,
  Container,
  Text,
} from "@mantine/core";

import Link from "next/link";
import Image from "next/image";

const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    [theme.fn.smallerThan("md")]: {
      marginLeft: "-2.5rem",
    },
  },

  content: {
    maxWidth: rem(600),
    marginRight: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  control: {
    color: "white",
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    width: "100%",
    marginTop: "-5rem",
    borderRadius: ".5rem",
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
  lineImage: {
    flex: 1,
    marginTop: "-5rem",
    borderRadius: ".5rem",
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },
}));

const Inicio = () => {
  const { classes } = useStyles();
  return (
    <div className="bg-light-verdeEscuroCTX p-16 w-full h-[40rem]">
      <Container size={"xl"}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <span className="text-5xl text-white w-full">
              Abordagem integral e natural para a sua saúde.
            </span>
            <Text color="white" mt="md">
              A Dra. Gabriella Martins pode ajudá-lo(a) a alcançar o bem-estar
              através de terapias personalizadas e eficazes.
            </Text>
            <Link
              href={
                "https://agendaronline.amplimed.com.br/gabriella-de-oliveira-martins"
              }
              target="_blank"
            >
              <Group mt={30} mb={30}>
                <Button
                  variant="light"
                  radius="xl"
                  size="md"
                  className={`${classes.control} bg-light-verdeClaroCTX`}
                >
                  Agende sua Consulta
                </Button>
              </Group>
            </Link>
          </div>

          <Image
            src={"/inicio/garota_inicio.png"}
            alt="Garota segurando a planta"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 70vw, 43vw"
            width={640}
            height={425}
            className={classes.image}
            priority={true}
          />
        </div>
      </Container>
    </div>
  );
};

export default Inicio;
