import { Group, Title, Divider, Text, Container } from "@mantine/core";

const CannabisHistory = () => {
  return (
    <Container size={"xl"}>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Medicina Canabinoide: Inovação Terapêutica e Uso Responsável da
          Cannabis Medicinal
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          Bem-vindo ao nosso site sobre Medicina Canabinoide! Aqui você
          encontrará informações confiáveis e dicas sobre o uso responsável da
          cannabis medicinal. Vamos falar sobre a história, a legalidade, os
          componentes da cannabis, o sistema endocanabinoide, as doenças que
          podem ser tratadas e as pesquisas científicas nessa área.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          História da Medicina Canabinoide:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          Remontando a civilizações antigas, a Medicina Canabinoide reconhece os
          benefícios terapêuticos da cannabis. Avanços científicos recentes nos
          permitiram compreender melhor os compostos da planta e seu potencial
          terapêutico.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Situação Jurídica/Legal no Brasil e no Mundo:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          No Brasil, a utilização da cannabis medicinal é regulamentada pela
          ANVISA e requer prescrição médica. Em outros países, as leis variam,
          desde a legalização completa até o uso restrito. É crucial estar
          ciente das regulamentações específicas de sua região para utilizar a
          cannabis medicinal dentro das normas legais.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Compostos Importantes da Cannabis e seus Efeitos:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          A cannabis contém diversos compostos ativos, sendo os mais estudados o
          THC e o CBD. O THC possui efeitos psicoativos, enquanto o CBD oferece
          propriedades terapêuticas sem alterar a consciência. Além desses,
          outros compostos, como o CBG, o CBN e os terpenos, também desempenham
          papéis importantes nos efeitos terapêuticos da cannabis.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Os Terpenos e o Efeito Comitiva:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          Os terpenos são substâncias aromáticas encontradas na cannabis e em
          muitas outras plantas. Eles contribuem para o aroma e o sabor
          característicos da planta e desempenham um papel essencial no efeito
          comitiva. O efeito comitiva refere-se à interação sinérgica entre os
          diferentes compostos da cannabis, como os terpenos, o THC e o CBD, que
          potencializa os benefícios terapêuticos da planta.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Uso Principal e Mais Disseminado da Cannabis:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          A cannabis medicinal tem sido utilizada com sucesso para tratar uma
          variedade de sintomas e patologias. Entre os usos mais comuns estão o
          alívio da dor crônica, o tratamento da espasticidade em doenças
          neurológicas, o controle de náuseas e vômitos relacionados à
          quimioterapia, o combate à ansiedade e distúrbios do sono, e o
          controle de convulsões em casos de epilepsia refratária. O efeito
          comitiva contribui para a eficácia desses tratamentos, permitindo uma
          abordagem mais holística e personalizada.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 break-all">
          Sistema Endocanabinoide:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          Nosso corpo possui um sistema endocanabinoide, um sistema de
          sinalização composto por receptores e endocanabinoides produzidos
          internamente. Esse sistema desempenha um papel crucial na regulação de
          funções fisiológicas como sono, apetite, dor e humor. A descoberta
          desse sistema forneceu uma base científica sólida para o uso da
          cannabis medicinal.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Segurança e Uso Responsável:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          Garantir a segurança e o uso responsável da cannabis medicinal é
          fundamental. É importante buscar orientações de profissionais de saúde
          qualificados, que avaliarão individualmente cada caso e prescreverão a
          dose adequada, considerando a relação entre os compostos da cannabis e
          os sintomas ou patologias a serem tratados.
        </Text>
      </Group>
      <Group spacing={6} mt={60}>
        <Title className="text-light-textoClaroCTX text-4xl font-medium w-full leading-snug before:content-['•'] before:-ml-7 before:mr-3 ">
          Pesquisas e Avanços na Medicina Canabinoide:
        </Title>
        <Divider size={"md"} w={300} color="#D6FF93" />
        <Text align="left" size={"lg"} color="#00332D">
          A pesquisa científica sobre a cannabis medicinal está em constante
          evolução. Estudos clínicos e observacionais são conduzidos para
          aprofundar o conhecimento sobre os benefícios terapêuticos da
          cannabis, sua segurança e as melhores práticas de uso. Esses avanços
          são essenciais para aprimorar a prescrição e o uso adequado da
          cannabis medicinal. A Medicina Canabinoide representa uma abordagem
          terapêutica inovadora, capaz de oferecer alívio e melhor qualidade de
          vida para muitos pacientes. É fundamental respeitar as regulamentações
          legais, contar com orientação médica especializada e utilizar a
          cannabis medicinal de forma responsável, buscando sempre os melhores
          resultados para a saúde. Agende uma consulta online para obter
          informações personalizadas e orientações sobre o uso adequado da
          cannabis medicinal para suas necessidades específicas.
        </Text>
      </Group>
    </Container>
  );
};

export default CannabisHistory;
